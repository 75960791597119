export const cleanFalsyValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (![undefined, null, '', NaN].includes(value)) {
      acc[key] = value
    }

    return acc
  }, {})
}

export const deepCopy = (obj: Record<string, any>): Record<string, any> => {
  return JSON.parse(JSON.stringify(obj))
}

export const omit = (obj, key) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: omitted, ...rest } = obj

  return rest
}
